<template lang="pug">
.brand-pricing-table-master-plan
  .content
    .section.col-title-cta
      .content-wrapper
        .title-wrapper
          .title {{ $t('masterPlanBox.title') }}
          .title(v-if="$i18n.locale !== 'hu'") {{ $t('masterPlanBox.altTitle') }}
        .action
          om-button.optimonk-click(primary @click="$emit('action')") {{ $t('contactUs') }}
    .section.col-features
      .items
        .item
          span.font-weight-bold
            fa-icon.font-size-0--875.mr-2(variant="fa-check")
            | {{ $t(`masterPlanBox.pageView`) }}
        .item
          span
            fa-icon.font-size-0--875.mr-2(variant="fa-check")
            | {{ $t(`masterPlanBox.domains`) }}
            popper(trigger="hover")
              .popper.brand-pricing-table-item-popper.brand-pricing-table-item-popper-small.w-20.p-3
                span {{ $t('planMisc.sitesDescription') }}
              span(slot="reference")
                i.fas.fa-info-circle.ml-1.align-items-center
        .item
          span
            fa-icon.font-size-0--875.mr-2(variant="fa-check")
            | {{ $t(`masterPlanBox.campaigns`) }}
        template(v-for="(value, property, index) in getMasterPlanItems")
          .item(v-if="property")
            span(v-if="value === 'yes'")
              fa-icon.font-size-0--875.mr-2(variant="fa-check")
              | {{ $t(`planDetailTitles.${property}`) }}
    .section.col-waving-monk
      img.waving-img.mx-auto(src="@/assets/monk-hi-user.svg" style="height: 14rem")
</template>
<script>
  import { getFreemiumPlanList } from '@/config/plans';

  export default {
    computed: {
      getMasterPlanItems() {
        const { prioritySupport, unbranded } = getFreemiumPlanList().find(
          ({ name }) => name === 'MASTER',
        ).details;
        return {
          unbranded,
          prioritySupport,
        };
      },
    },
  };
</script>
<style lang="sass" scoped>
  .brand-pricing-table-master-plan
    margin-top: 2rem
    background: #FFEFE5
    border-radius: 8px
    display: flex
    flex-direction: column
    overflow: hidden
    .content
      display: flex
      margin-top: 2rem
      margin-bottom: 2rem
      .section
        display: flex
        &.col-title-cta
          margin-right: 4.5rem
        &.col-waving-monk
          position: relative
          flex-grow: 1
          img
            position: absolute
            right: 3.125rem
          @media screen and (max-width: 1070px)
            display: none
        .items
          display: flex
          flex-flow: column nowrap
          .item
            font-size: 0.875rem
            margin: 0.3125rem 0.625rem
            ::v-deep.fa-info-circle
              color: #B9BEC6
            ::v-deep i.fa-check
              color: #2CC896
        .content-wrapper
          margin-left: 4.375rem
          .title-wrapper
            max-width: 11.25rem
            .title
              font-weight: 700
              font-size: 1.25rem
              line-height: 1.75rem
              margin-bottom: 0
              text-align: left
          .action
            margin-top: 1.25rem

  @media screen and (max-width: 768px)
    .brand-pricing-table-master-plan
      max-height: 100%
      .content
        flex-direction: column
      .section
        &.col-features
          margin-top: 2.5rem
          margin-left: 1.5625rem
          margin-bottom: 1.25rem
        .content-wrapper
          margin-left: 2.375rem !important
</style>
